<template>
  <div class="home-wrapper main-wrapper">
    <div class="notice" v-if="JSON.stringify(notice) !== '{}'">
      <div class="three-info bbsInfo" v-if="bbsData.length > 0">
        <vue-seamless-scroll :data="bbsData" :class-option="bbsOption">
          <div class="info-item">
            <img src="@/assets/images/home/notice.png" alt />
            <p>{{ bbsData[0].Title }}:{{ bbsData[0].Content }}</p>
          </div>
        </vue-seamless-scroll>
      </div>
    </div>



    <!-- <div class="qualifications" v-if="IsLuckDraw">
      <div class="title">
        <img src="@/assets/images/blindBox/ps.png" alt="" />
        温馨提示
      </div>
      <div class="content">
        <p>恭喜您！获得抽取盲盒资格</p>
        <router-link to="/blindBox">立马抽盲盒</router-link>
      </div>
    </div> -->
    <div class="top-box">
      <div class="left-box">
        <div class="recmd-game">
          <div class="title-box">
            <span class="title">
              <img src="@/assets/img/icon-1.png" alt="" />
              推荐游戏
            </span>
            <router-link to="/home" class="more">
              更多游戏
              <i class="el-icon-d-arrow-right"></i>
            </router-link>
          </div>
          <ul class="game-box">
            <li v-for="(item, index) in gameList" :key="index"
              @click="toFreetoplay(item.gameInfoEntity.Name, item.ProductID)" v-show="index < 3">
              <div class="content">
                <div :style="{
                  height: '108px',
                  background: `url(${item.gameInfoEntity.PicList[0]}?x-oss-process=image/resize,h_124)`,
                  backgroundSize: '100% 108px',
                  borderRadius: '6px 6px 0 0',
                }"></div>

                <h4 v-if="item.gameInfoEntity.Type == 2 ||
                  item.gameInfoEntity.Type == 3
                  ">
                  {{ beautySub(item.gameInfoEntity.Name, 4) }}
                  <span class="cloud-archiving">支持存档</span>
                </h4>
                <h4 v-else>{{ item.gameInfoEntity.Name }}</h4>

                <div class="show">
                  <el-button class="start">登录游戏</el-button>
                </div>
                <div class="hidden">
                  <div class="tag-box">
                    <el-tag v-for="(i, index) in item.gameInfoEntity.Labels.split(
                      ','
                    )" :key="index">{{ i }}</el-tag>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>

        <div class="pic-box" v-if="netBarVIP && zsAdImg.length">
          <a href="javascript:;" @click="linkTo(zsAdImg[0].Url)" class="pic-item">
            <img :src="zsAdImg[0].ImgUrl" alt />
          </a>
          <a href="javascript:;" @click="linkTo(zsAdImg[1].Url)" class="pic-item">
            <img :src="zsAdImg[1].ImgUrl" alt />
          </a>
        </div>

        <div class="pic-box" v-if="!netBarVIP && hjAdImg.length">
          <a href="javascript:;" @click="linkTo(hjAdImg[0].Url)" class="pic-item">
            <img :src="hjAdImg[0].ImgUrl" alt />
          </a>
          <a href="javascript:;" @click="linkTo(hjAdImg[1].Url)" class="pic-item">
            <img :src="hjAdImg[1].ImgUrl" alt />
          </a>
        </div>
      </div>
      <div class="search-box">
        <el-input ref="input" v-model="name" :placeholder="placeText" suffix-icon="el-icon-search" @focus="handleFocus"
          @blur="handleBlur"></el-input>

        <div class="live-room">
          <el-tabs v-model="activeName" @tab-click="handleClick">
            <!-- <CLiveRoom /> -->
            <el-tab-pane label="赛事预告" name="2">
              <!-- <a href="javascript:;" @click="toLive" v-if="liveRoomStart">
                <img src="@/assets/img/live-start.gif" alt />
              </a> -->
              <ul>
                <li class="item-box" v-for="(item, index) in PerfectArchives" :key="index" @click="toLive">
                  <div class="left">
                    <i class="el-icon-video-camera"></i>
                    <div>
                      <p class="time">{{ item.ActivityStartTime }}</p>
                      <el-tooltip class="item" effect="dark" :content="item.Title" placement="top">
                        <p class="text">{{ item.Title }}</p>
                      </el-tooltip>
                    </div>
                  </div>
                </li>
              </ul>
            </el-tab-pane>
            <!-- <el-tab-pane label="直播精彩回放" name="3">
              <ul>
                <li class="item-box" v-for="(item, index) in PerfectArchives" :key="index">
                  <div class="left">
                    <i class="el-icon-video-camera"></i>
                    <div>
                      <el-tooltip class="item" effect="dark" :content="item.Title" placement="top">
                        <p class="time">{{ item.Title }}</p>
                      </el-tooltip>
                      <p>{{ item.ActivityStartTime }}</p>
                    </div>
                  </div>
                  <div class="right" v-if="item.Remarks" @click="toLiveRoom(item.Remarks)">
                    <i class="el-icon-video-play"></i>
                    回放
                  </div>
                </li>
              </ul>
            </el-tab-pane> -->
          </el-tabs>
        </div>
        <!-- <div class="game-names">
          <p>热门搜索：</p>
          <div class="tags-box">
            <span v-for="(item, index) in keyWords" :key="index"
              @click="toLink(item.ProductID, item.gameInfoEntity.Name)">
              {{ item.gameInfoEntity.Name }}
            </span>
          </div>
        </div> -->

        <!-- 浮窗广告 -->
        <!-- <div class="ad-box" v-if="adObj">
          <img class="close" src="@/assets/images/home/close.png" alt="" @click="closeAdDialog" />
          <img :src="adObj.ADImageUrl" @click.stop="adToLink(adObj.ADOpenUrl)" alt="">
        </div> -->
      </div>
    </div>

    <!-- 热门游戏 -->
    <div class="hot-game">
      <div class="title-box">
        <span class="title">
          <img src="@/assets/img/icon-2.png" alt="" />
          {{ netBarVIP ? '热门游戏' : '周免游戏' }}
          <span v-if="!netBarVIP">每周精选，限时免费</span>
        </span>
        <router-link :to="netBarVIP ? '/home' : '/weeklyFreeGame'" class="more">
          更多游戏
          <i class="el-icon-d-arrow-right"></i>
        </router-link>
      </div>
      <ul class="game-box">
        <li v-for="(item, index) in hotGameList" :key="index"
          @click="toFreetoplay(item.gameInfoEntity.Name, item.ProductID)">
          <div class="icon" v-if="!netBarVIP">
            免费玩<br />
            价值：￥{{ item.gameInfoEntity.Originalprice && item.gameInfoEntity.Originalprice.toString().split(".")[0] }}
          </div>
          <div class="content" v-if="item && item.gameInfoEntity">
            <div :style="{
              height: '100px',
              background: `url(${item.gameInfoEntity.PicList[0]}?x-oss-process=image/resize,h_106)`,
              backgroundSize: '100% 100px',
              borderRadius: '6px 6px 0 0',
            }"></div>
            <h4 v-if="item.gameInfoEntity.Type == 2 || item.gameInfoEntity.Type == 3
              ">
              {{ beautySub(item.gameInfoEntity.Name, 4) }}
              <span class="cloud-archiving">支持存档</span>
            </h4>
            <h4 v-else>{{ item.gameInfoEntity.Name }}</h4>
            <div v-if="netBarVIP">
              <div class="show">
                <el-button class="start">登录游戏</el-button>
              </div>
              <div class="hidden">
                <div class="tag-box">
                  <el-tag v-for="(i, index) in item.gameInfoEntity.Labels.split(',')" :key="index">{{ i }}</el-tag>
                </div>
              </div>
            </div>

            <div class="free-time" v-else>
              本周限时免费
            </div>
          </div>
        </li>
      </ul>
    </div>

    <div class="hot-game">
      <div class="title-box">
        <span class="title">
          <img src="@/assets/img/icon-2.png" alt="" />
          新上架商品
        </span>
        <router-link to="/home" class="more">
          更多游戏
          <i class="el-icon-d-arrow-right"></i>
        </router-link>
      </div>
      <ul class="game-box">
        <li v-for="(item, index) in newGameList" :key="index"
          @click="toFreetoplay(item.gameInfoEntity.Name, item.ProductID)">
          <div class="content" v-if="item && item.gameInfoEntity">
            <div :style="{
              height: '100px',
              background: `url(${item.gameInfoEntity.PicList[0]}?x-oss-process=image/resize,h_106)`,
              backgroundSize: '100% 100px',
              borderRadius: '6px 6px 0 0',
            }"></div>
            <h4 v-if="item.gameInfoEntity.Type == 2 || item.gameInfoEntity.Type == 3
              ">
              {{ beautySub(item.gameInfoEntity.Name, 4) }}
              <span class="cloud-archiving">支持存档</span>
            </h4>
            <h4 v-else>{{ item.gameInfoEntity.Name }}</h4>
            <div class="show">
              <el-button class="start">登录游戏</el-button>
            </div>
            <div class="hidden">
              <div class="tag-box">
                <el-tag v-for="(i, index) in item.gameInfoEntity.Labels.split(',')" :key="index">{{ i }}</el-tag>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>

    <!-- 长条广告位 -->
    <!-- <div class="img-box" v-if="netBarVIP && zsAdImg.length">
      <a href="javascript:;" @click="linkTo(zsAdImg[2].Url)" class="pic-item">
        <img :src="zsAdImg[2].ImgUrl" alt />
      </a>
    </div>
    
    <div class="img-box" v-if="hjAdImg.length">
      <a href="javascript:;" @click="linkTo(hjAdImg[2].Url)" class="pic-item">
        <img :src="hjAdImg[2].ImgUrl" alt />
      </a>
    </div> -->

    <!-- 启动广告 -->
    <el-dialog :visible.sync="dialogVisible" custom-class="no-title-dialog" width="500px"
      v-if="startAd.length && startAd[0] && IsLuckDraw" center @close="closeStartAd">
      <div class="blind-box-img" style="padding: 30px">
        <a href="javascript:;" @click="linkTo(startAd[0].Url, 1)" style="display: block">
          <img :src="startAd[0].ImgUrl" width="100%" alt="" />
        </a>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  GetActivity2,
  GetSteamPower,
  GetTop1Msg,
  GetHomePageGames,
  GetNavigationList,
  GetGameKeyword,
  GetGameListNew,
} from "@/api";
import { mapGetters } from "vuex";
import cMethods from "@/util/cMethods.js";
import vueSeamlessScroll from "vue-seamless-scroll";
import CLiveRoom from "@/views/components/CLiveRoom.vue"

// 节流函数
const delay = (function () {
  let timer = 0;
  return function (callback, ms) {
    clearTimeout(timer);
    timer = setTimeout(callback, ms);
  };
})();

export default {
  name: "Home",
  components: {
    vueSeamlessScroll,
    CLiveRoom
  },
  computed: {
    ...mapGetters(["isLogin", "netBarId", "netBarVIP", "userInfo"]),
    bbsOption() {
      return {
        step: 0.5, // 数值越大速度滚动越快
        limitMoveNum: 1, // 开始无缝滚动的数据量 this.bbsData.length
        hoverStop: false, // 是否开启鼠标悬停stop
        direction: 2, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000 // 单步运动停止的时间(默认值1000ms)
      }
    }
  },
  data() {
    return {
      liveRoomStart: false,
      bbsData: [],
      dialogVisible: false,
      gameList: [],
      hotGameList: [],
      hjAdImg: [],
      zsAdImg: [],
      startAd: [],
      name: "",
      keyWords: [],
      notice: {},
      IsLuckDraw: false,
      placeText: "请输入您要搜索的游戏",
      newGameList: [],
      adObj: null,
      activeName: '2',
      PerfectArchives: [],
      reverse: true,
      // liveText: "赛事预告",
      startTime: 0, //直播开始状态
      timer: null
    };
  },
  created() {
    if (this.userInfo && this.userInfo.IsLuckDraw) {
      this.IsLuckDraw = true;
    }

    // 获取赛事列表
    this._GetGameList(this.activeName);
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }
  },
  mounted() {
    // 广告图(没有打开)
    if (!sessionStorage.getItem("hideStartAd")) {
      this._GetNavigationList(7);
    }

    if (this.netBarId) {
      this._GetSteamPower();
    }
    // 获取公告
    this._GetTop1Msg();

    // 热门搜索
    this._GetGameKeyword();

    // 新上架商品
    this._GetNewGame();
  },
  methods: {
    // 直播中倒计时
    // liveRoomRuning() {
    //   let nowTime = new Date().getTime()
    //   console.log(this.startTime, nowTime)
    //   if (this.startTime !== 0 && this.startTime <= nowTime) {
    //     this.liveRoomStart = true;
    //     this.liveText = "直播中"
    //   }
    // },
    toLive() {
      this.$statistic(['B', '首页', '直播', '点击人数']);
      this.$router.push('/eSports');
    },
    // 回放
    toLiveRoom(url) {
      this.$statistic(['B', '直播', '回放', '点击人数']);
      this.$router.push({
        path: 'LiveRoom',
        query: {
          url: encodeURIComponent(url)
        }
      })
    },
    // 获取游戏列表
    async _GetGameList(index) {
      if (this.netBarId) {
        let params = {
          netbarId: this.netBarId,
          pageSize: 3,
          pageIndex: 1,
          state: index,
          userId: (this.userInfo && this.userInfo.ConsumerId) || 0,
          gameName: "绝地求生"
        };
        let res = await GetActivity2(params);
        if (res.StatusCode == 200) {
          this.PerfectArchives = res.Items.reverse();
          if (index === '2' && this.PerfectArchives.length) {
            let t = new Date(this.PerfectArchives[0].ActivityStartTime)
            this.startTime = t.getTime()
            clearTimeout(this.timer);

            // this.timer = setInterval(() => {
            //   this.liveRoomRuning();
            // }, 1000);
          }
        }
      } else {
        this.$message({
          message: "获取网吧ID失败！",
          type: "error",
        });
      }
    },
    handleClick(tab, event) {
      // 获取赛事列表
      this._GetGameList(tab.name);
    },
    handleFocus() {
      this.placeText = "";
    },
    handleBlur() {
      this.placeText = "请输入您要搜索的内容";
    },
    // 获取新上架商品
    async _GetNewGame() {
      let params = {
        source: 3,
        keyword: "",
        pageSize: 5,
        pageIndex: 1,
        labels: "",
        platformId: "",
        // isNewGame: true,
      };
      let res = await GetGameListNew(params);
      if (res.StatusCode == 200) {
        this.newGameList = res.Items;
      }
    },
    // 获取网吧版本
    async _GetSteamPower() {
      let params = {
        netbarId: this.netBarId,
      };
      let res = await GetSteamPower(params);
      // 如果是钻石版跳转列表页
      if (res.Object) {
        // 推荐游戏
        this._GetHomePageGames(1);
        // 广告图
        this._GetNavigationList(2);
      } else {
        // 推荐游戏
        this._GetHomePageGames(2);
        // 广告图
        this._GetNavigationList(1);
      }
    },
    linkTo(url, num) {
      let reg =
        /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\*\+,;=.]+$/;
      if (!reg.test(url)) {
        if (url.indexOf("/link") > -1) {
          this.$router.push({ path: "/link", query: { url: url } });
        } else {
          this.$router.push(url);
        }
      } else {
        cMethods.OpenIeUrl(url);
      }
      if (num == 1) {
        this.closeStartAd();
      }
    },
    // 关闭启动广告
    closeStartAd() {
      this.dialogVisible = false;
      sessionStorage.setItem("hideStartAd", true);
    },
    // 获取广告图
    async _GetNavigationList(type) {
      let params = {
        type: type,
      };
      let res = await GetNavigationList(params);
      if (res.StatusCode) {
        if (type == 1) {
          this.hjAdImg = res.Object;
        }
        if (type == 2) {
          this.zsAdImg = res.Object;
        }
        if (type == 7) {
          this.startAd = res.Object;
          if (
            this.startAd.length &&
            this.startAd[0] &&
            this.startAd[0].ImgUrl
          ) {
            this.dialogVisible = true;
          }
        }
      }
    },
    // 公告
    async _GetTop1Msg() {
      let params = {};
      let res = await GetTop1Msg(params);
      if (res.StatusCode == 200) {
        this.bbsData = [];
        if (res.Object != null) {
          this.notice = res.Object;
        } else {
          this.notice = {};
        }
        this.bbsData.push(this.notice)
      }
    },
    //获取平台、网吧用户和游戏数据
    async _GetHomePageGames(type) {
      let params = {
        type: type,
        navigationType: 3,
      };
      let res = await GetHomePageGames(params);
      if (res.StatusCode == 200) {
        let data = res.Object;
        this.gameList = data.slice(0, 3);
        this.hotGameList = data.slice(3, 8);
        if (this.hotGameList.length) {
          this.setWeeklyFreeGame(this.hotGameList)
        }
      }
    },
    setWeeklyFreeGame(arr) {
      let strArr = []
      arr.map(item => {
        strArr.push(item.ProductID)
      })
      sessionStorage.setItem('weeklyFreeGame', JSON.stringify(strArr))
    },
    // 热门游戏跳转
    toFreetoplay(Name, ProductID) {
      // this.$statistic(["B", "本周最热游戏", "点击", Name]);
      this.$router.push(`/gameDetail/${ProductID}`);
    },
    // 跳转淘号帮
    toTHB() {
      cMethods.OpenIeUrl("http://www.taohaobang.com/gameaccount?code=580_PC");
    },
    beautySub(str, len) {
      var reg = /[\u4e00-\u9fa5]/g, //匹配中文
        slice = str.substring(0, len),
        chineseCharNum = ~~(slice.match(reg) && slice.match(reg).length),
        realen = slice.length * 2 - chineseCharNum;
      return str.substr(0, realen) + (realen < str.length ? "..." : "");
    },
    async _GetGameKeyword() {
      let res = await GetGameKeyword({});
      if (res.StatusCode == 200) {
        this.keyWords = res.Object;
      }

      // 悬浮广告
      if (sessionStorage.getItem('adList') && !sessionStorage.getItem('closeAd')) {
        let adArr = JSON.parse(sessionStorage.getItem('adList'))
        if (adArr) {
          this.adObj = adArr.filter(item => item.ADName === '首页浮动广告')[0]
        }
      }
    },
    toLink(ProductID, Name) {
      this.$router.push("/gameDetail/" + ProductID);
      this.$statistic(["B", "热门搜索", "点击", Name]);
    },
    closeAdDialog() {
      this.adObj = null;
      sessionStorage.setItem('closeAd', 1)
    },
    // 登录成功弹窗跳转
    adToLink(url) {
      // this.adDialog = false;
      this.$router.push(url)
    }
  },
  watch: {
    //监听搜索，300ms执行一次查询方法（去搜索）
    name() {
      delay(() => {
        if (this.name) {
          this.$router.push("/home?name=" + this.name);
        }
      }, 300);
    },
  },
};
</script>

<style lang="scss" scoped>
.home-wrapper {
  position: relative;

  .qualifications {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 99;
    width: 258px;
    height: 118px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    background: $white-col;

    .title {
      width: 248px;
      height: 30px;
      line-height: 30px;
      padding-left: 10px;
      border-bottom: 1px solid $border-col;
      color: #e71e88;
      font-size: 16px;

      img {
        vertical-align: middle;
        margin-right: 5px;
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      @include font_col3();

      p {
        margin: 10px 0;
        font-size: 16px;
      }

      a {
        width: 120px;
        height: 30px;
        line-height: 30px;
        display: block;
        text-align: center;
        background: #e71e88;
        border-radius: 0px 6px 0px 6px;
        color: $white-col;
      }
    }
  }

  .notice {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99;
    width: 100%;
    line-height: 30px;
    padding: 0 10px;
    box-sizing: border-box;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 22px;
      height: 18px;
      margin-top: 5px;
      margin-right: 10px;
    }

    p {
      font-size: 16px;
      color: #ff6d1e;
      margin-right: 20px;
    }


    .three-info {
      width: 100%;
      border-radius: 2px;
    }

    .info-item {
      display: flex;
      // padding: 10px 0;
    }

    // .info-item .info-tag {
    //   width: 62px;
    //   padding: 6px 10px;
    //   background: #3E91FF;
    //   color: #fff;
    //   text-align: center;
    //   border-radius: 6px;
    // }

    .bbsInfo {
      color: #fff;
      overflow: hidden;
    }
  }

  .top-box {
    height: 296px;
    // display: flex;
    justify-content: space-between;

    .left-box {
      width: 700px;
      height: 296px;
      overflow: hidden;
      float: left;

      .recmd-game {
        height: 196px;

        .title-box {
          height: 20px;
          line-height: 20px;
          font-size: 16px;
          box-sizing: border-box;
          display: flex;
          justify-content: space-between;
          @include font_col3();

          .title {
            display: flex;
            align-items: center;

            img {
              width: 20px;
              margin-right: 8px;
            }
          }

          .more {
            float: right;
            font-size: 14px;
            @include font_col3();
          }
        }

        .game-box {
          margin-top: 8px;
          display: flex;
          justify-content: space-between;

          li {
            width: 220px;
            height: 160px;
            border-radius: 6px;
            box-sizing: border-box;
            text-align: center;
            @include item_bg_col();

            h4 {
              font-size: 16px;
              line-height: 24px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              @include font_col3();

              .cloud-archiving {
                padding: 2px 5px;
                display: inline-block;
                margin-left: 10px;
                background: $linear-col;
                color: $white-col;
                border-radius: 4px;
                font-size: 12px;
                line-height: 18px;
              }
            }

            .tag-box {
              overflow: hidden;
              height: 18px;

              .el-tag {
                margin: 0 5px;
                background: none !important;
                height: 18px;
                line-height: 18px;
                border-radius: 4px;
                @include font_col9();
              }
            }

            .show {
              // margin-top: 4px;
              display: none;

              .start {
                width: 196px;
                padding: 5px 10px;
                border: transparent;
                color: $white-col;
                background: $red-col;
              }
            }
          }

          li:hover {
            transform: translateY(-5px);
            transition: 0.2s ease;
            cursor: pointer;
            box-shadow: -2px 0px 10px 0px rgba(0, 0, 0, 0.2);

            .show {
              display: block;
            }

            .hidden {
              display: none;
            }

            h4 {
              color: $red-col;
            }
          }
        }
      }

      .pic-box {
        margin-top: 8px;
        display: flex;
        justify-content: space-between;

        img {
          width: 341px;
          height: 92px;
          border-radius: 6px;
        }
      }
    }

    // .collection-box {
    //   width: 298px;
    //   p {
    //     line-height: 14px;
    //     font-size: 15px;
    //     @include font_col6();
    //     display: flex;
    //     // justify-content: space-between;
    //     span {
    //       margin-left: 160px;
    //       font-size: 12px;
    //     }
    //     &::before {
    //       width: 5px;
    //       height: 14px;
    //       content: " ";
    //       background: red;
    //       margin-right: 5px;
    //     }
    //   }
    //   .item {
    //     margin-top: 5px;
    //   }
    // }
    .search-box {
      width: 298px;
      height: 280px;
      float: right;
      padding: 8px 12px;
      border-radius: 6px;
      position: relative;

      ::v-deep .el-input__inner {
        border: none;
        @include font_col6();
        @include input_bg_color();
      }

      ::v-deep .el-input__inner:focus {
        border-color: $red-col !important;
      }

      @include item_bg_col();

      .live-room {

        .el-tabs {
          border-radius: 6px;
          @include item_bg_col();

          ::v-deep .el-tabs__nav-wrap::after {
            height: 0;
            background-color: transparent;
          }

          ::v-deep .el-tabs__item {
            @include font_col3()
          }

          ::v-deep .el-tabs__header {
            margin-bottom: 0;
          }

          ::v-deep .el-tabs__item.is-active,
          ::v-deep .el-tabs__item:hover {
            color: $red_col;
          }

          ::v-deep .el-tabs__active-bar {
            background-color: $red_col;
          }
        }

        .item-box {
          display: flex;
          justify-content: space-between;
          align-items: center;
          @include font_col3();
          margin-top: 15px;
          box-sizing: border-box;

          .left {
            display: flex;
            flex: 1;
            align-items: center;

            .text {
              width: 260px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }

            .time {
              width: 200px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }

            ::v-deep .el-icon-video-camera {
              font-size: 26px;
              @include font_col3();
              margin-right: 10px;
            }
          }

          .right {
            display: flex;
            align-items: center;
            cursor: pointer;

            ::v-deep .el-icon-video-play {
              font-size: 20px;
              // @include font_col3();
              margin-right: 5px;
              color: #ff3636;
            }
          }

          // .left::before {
          //   width: 24px;
          //   height: 24px;
          //   content: ' ';
          //   position: absolute;
          //   top: 10px;
          //   background: url('../assets/img/live-end.png') no-repeat;
          //   background-size: 100%;
          // }

          p {
            // padding-left: 32px;
            line-height: 24px;
          }

          & .time {
            font-size: 18px;
          }
        }
      }

      .game-names {
        p {
          line-height: 44px;
          font-size: 16px;
          @include font_col6();
        }

        .tags-box {
          height: 176px;

          // display: flex;
          // flex-wrap: wrap;
          // overflow: hidden;
          span {
            font-size: 12px;
            padding: 8px 10px;
            display: inline-block;
            margin: 0 5px 10px 0;
            border-radius: 4px;
            @include font_col6();
            @include tag_bg_col();
            box-sizing: border-box;
            cursor: pointer;
          }

          // .el-tag {
          //   margin: 0 8px 0 0;
          //   border-radius: 4px;
          //   border: transparent;
          //   height: 24px;
          //   line-height: 24px;
          //   box-sizing: border-box;
          //   @include font_col6();
          //   @include tag_bg_col();
          // }
          .el-tag:hover {
            cursor: pointer;
            color: $red-col;
            // border: 1px solid $red-col;
          }
        }
      }

      .ad-box {
        position: absolute;
        top: 170px;
        right: 0;

        .close {
          position: absolute;
          top: 5px;
          right: 5px;
          cursor: pointer;
        }
      }
    }
  }

  .hot-game {
    margin-top: 10px;
    height: 180px;
    border-radius: 6px;

    .title-box {
      height: 20px;
      line-height: 20px;
      font-size: 16px;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      @include font_col3();

      .title {
        display: flex;
        align-items: center;

        img {
          width: 20px;
          margin-right: 8px;
        }

        span {
          font-size: 14px;
          margin-left: 15px;
          color: #ff3636;
        }
      }

      .more {
        float: right;
        font-size: 14px;
        margin-right: 10px;
        @include font_col3();
      }
    }

    .game-box {
      margin-top: 8px;
      display: flex;
      justify-content: space-between;

      li {
        width: 190px;
        height: 150px;
        border-radius: 6px;
        box-sizing: border-box;
        text-align: center;
        @include item_bg_col();
        position: relative;

        .icon {
          width: 111px;
          height: 36px;
          display: flex;
          align-items: center;
          justify-content: center;

          position: absolute;
          top: -5px;
          left: 5px;
          color: #fff;
          font-size: 12px;
          line-height: 14px;
          background: url('./../assets/img/left_icon1.png') no-repeat;
        }

        h4 {
          font-size: 15px;
          line-height: 24px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          @include font_col3();

          .cloud-archiving {
            padding: 2px 5px;
            margin-left: 10px;
            background: $linear-col;
            border-radius: 4px;
            color: $white-col;
            font-size: 12px;
            line-height: 18px;
          }
        }

        .free-time {
          width: 180px;
          padding: 3px 10px;
          margin: 0 auto;
          border-radius: 4px;
          color: #ff682d;
          background: transparent;
          border: 1px solid #ff682d;
          box-sizing: border-box;
        }

        .tag-box {
          overflow: hidden;
          // margin-top: 5px;
          height: 18px;

          .el-tag {
            margin: 0 5px;
            background: none !important;
            height: 18px;
            line-height: 18px;
            border-radius: 4px;
            @include font_col9();
          }
        }

        .show {
          display: none;

          .start {
            width: 180px;
            padding: 5px 10px;
            border: transparent;
            color: $white-col;
            background: $red-col;
          }
        }
      }

      li:hover {
        transform: translateY(-5px);
        transition: 0.2s ease;
        cursor: pointer;
        box-shadow: -2px 0px 10px 0px rgba(0, 0, 0, 0.2);

        .show {
          display: block;
        }

        .hidden {
          display: none;
        }

        h4 {
          color: $red-col;
        }

        .free-time {
          color: #fff;
          background: linear-gradient(90deg, #FF9A68 0%, #FF6868 100%);
        }
      }
    }
  }

  .img-box {
    height: 92px;
    margin-top: 14px;

    img {
      width: 100%;
      height: 93px;
      border-radius: 6px;
    }
  }

  // 广告闪光效果
  .pic-item {
    position: relative;
    overflow: hidden;
    display: block;
  }

  .pic-item:hover {
    transform: translateY(-5px);
    transition: 0.5s ease;
  }

  .pic-item:after {
    position: absolute;
    left: -100%;
    /*改变left的值，让其相对box影藏*/
    top: 0;
    width: 30%;
    height: 100%;
    content: "";
    /* Safari 5.1 - 6.0 */
    background: -webkit-linear-gradient(left,
        rgba(255, 255, 255, 0) 0,
        rgba(255, 255, 255, 0.6) 50%,
        rgba(255, 255, 255, 0) 100%);
    /* Opera 11.1 - 12.0 */
    background: -o-linear-gradient(left,
        rgba(255, 255, 255, 0) 0,
        rgba(255, 255, 255, 0.6) 50%,
        rgba(255, 255, 255, 0) 100%);
    /* Firefox 3.6 - 15 */
    background: -moz-linear-gradient(left,
        rgba(255, 255, 255, 0) 0,
        rgba(255, 255, 255, 0.6) 50%,
        rgba(255, 255, 255, 0) 100%);
    /* 标准的语法 */
    background: linear-gradient(to right,
        rgba(255, 255, 255, 0) 0,
        rgba(255, 255, 255, 0.6) 50%,
        rgba(255, 255, 255, 0) 100%);
    transform: skewX(-45deg);
  }

  .pic-item:hover:after {
    left: 150%;
    transition: 1s ease;
  }
}

.no-title-dialog {
  padding: 50px;
}
</style>
